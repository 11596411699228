/**
 * Shows notification params
 * @constructor
 * @param {string} type - Type should be one of this "success, waring, error"
 * @param {string} title - title of notification
 * @param {boolean} autoClose - If set to false, the notification needs to be closed manually
 * @param {number} delay - removed after some milliseconds
 * @param {string} position - Position should be one of this 'top-right, top-left, top-center, bottom-left, bottom-center, bottom-right'
 */

import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";

document.addEventListener("DOMContentLoaded", () => {
  _$("body").prepend(_$("<div>").addClass("Toastify").el());
});

const renderIcon = {
  success: '<i class="icon-ok-circle"></i>',
  error: '<i class="icon-exclamation-sign"></i>',
  warning: '<i class="icon-info-sign"></i>',
};

let currentNotifications = {};

export const toast = ({
  type = "success",
  position = "top-right",
  title,
  message,
  autoClose = false,
  delay = 3000,
}) => {
  const currentNotificationKey = `${title}_${message}_${type}_${position}`;

  if (currentNotifications[currentNotificationKey]) {
    return;
  }

  const uniqueId = utils.getUId();

  let toastifyContainer = _$(
    `.Toastify__toast-container.Toastify__toast-container--${position}`
  ).el();

  if (!toastifyContainer) {
    toastifyContainer = document.createElement("div");
    toastifyContainer.classList.add(
      "Toastify__toast-container",
      `Toastify__toast-container--${position}`
    );
    document
      .querySelector(".Toastify")
      .insertAdjacentElement("afterbegin", toastifyContainer);
  }

  const closeNotification = (id) => {
    if (id) {
      const toastElement = document.getElementById(id);
      if (toastElement) {
        toastElement.classList.add("remove-animation");
        toastElement.addEventListener("animationend", () => {
          toastElement.remove();
          delete currentNotifications[currentNotificationKey];
        });
      }
    }
  };

  const toastDiv = document.createElement("div");
  toastDiv.classList.add("toast", `toast-${type}`);
  toastDiv.setAttribute("id", uniqueId);
  toastDiv.innerHTML += `<div class="toast-icon"> ${
    renderIcon[type]
  } </div><div class="text-sm is-flex-grow-1 text-primaryMain">${
    message ? `<p class="text-sm text-bold">${message}</p>` : ""
  } ${title && `<p class="text-sm">${title}</p>`}</div>`;

  const closeButton = document.createElement("button");
  closeButton.classList.add("toast-close-btn");
  closeButton.innerHTML = '<i class="icon-remove"></i>';
  closeButton.onclick = () => {
    closeNotification(uniqueId);
  };
  toastDiv.insertAdjacentElement("beforeend", closeButton);

  toastifyContainer.insertAdjacentElement("beforeend", toastDiv);

  currentNotifications[currentNotificationKey] = uniqueId;

  if (autoClose) {
    window.setTimeout(closeNotification.bind(null, uniqueId), delay);
  }
};

export const growl = (title, message, timeout, error) => {
  toast({
    title,
    message,
    delay: timeout ? timeout : undefined,
    autoClose: timeout ? true : false,
    type: error ? "error" : "success",
  });
};
